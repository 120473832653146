$myColor: '#999999';

body {
/*    font-family: 'source-han-sans-traditional', sans-serif;*/
    font-size: 1em;
    font-weight: 300;
    margin: 0 40px;
    color: #555555;
}

h1 {
    color: #444444;
}

a {
    color: #444444;
    text-decoration: none;
}

a:hover {
    color: #333333;
    background-color: rgb(239, 239, 239);
    display: inline-block;
}

ol {
    left: 20px;
    position: relative;
}

ul {
    
}

sup {
    top: 5px;
    margin-right: 8px;
}

#content {
    margin-top: 30px;
}

.logo-bib {
    color: #00719E;
}

.logo-le {
    color: #366CB4;
}

.logo-arn {
    color: #006CDB;
}

.home-gif {
    box-shadow: 2px 2px 13px #afafaf;
}

.video-player-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* for 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
}

.video-player-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tutorial-gif {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-container {
    width: 90%;
}

.cardTitle {
    font-weight: 400;
    font-size: 0.8em;
    color: #333333;
    cursor: pointer;
    float: left;
    margin: 2px 6px;
}

.bookTileSmall {
    width: 30px;
    text-align: center;
    float: left;
    padding: 2px;
    margin: 2px 6px;
}

.bookTileSmall:hover {
    color: #333333;
    border: 1px dotted #333333;
    border-radius: 4px;
    cursor: 'pointer';
    padding: 0px;
    margin: 1px 7px;
    position: relative;
}

.prev-next-nav {
    width: 100%;
    position: relative;
}

.prev-next-nav-button {
    width: 35px;
}

.prev-next-nav-icon {
    left: -6px;
}

.my-div {
    width: 100%;  /* Default mobile styles */
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    height: 100vh;
    border-left: 1px solid #efefef;
    animation: slide-in 0.5s ease-out forwards;
    background: white;
    padding: 30px;
    box-shadow: 20px 20px 50px #888888;
    z-index: 999;
}

.chat-title {
    top: -20px;
    position: relative;
    text-align: center;
}

.current {
/*    border-bottom: 2px solid #ff0000;*/
}

a.chapter-grid {
    color: #6f38c3;
    float: left;
    width: 30px;
    height: 18px;
    padding: 0px 3px 2px 1px;
    font-size: 13px;
    text-decoration: none;
    text-align: center;
}

a.chapter-grid:hover {
    color: #ffffff;
    padding: 0px 0px 0px 0px;
    background-color: #b3adbe;
    border-radius: 8px;
    position: relative;}

a.book-grid {
    color: #6f38c3;
    float: left;
    width: 40px;
    height: 18px;
    padding: 0px 3px 2px 1px;
    font-size: 13px;
    text-decoration: none;
    text-align: center;
}

a.book-grid:hover {
    color: #ffffff;
    padding: 0px 0px 1px 0px;
    background-color: #b3adbe;
    font-size: 13px;
    border-radius: 6px;
    position: relative;
}

.selected-chapter-grid {
    background-color: #b3adbe;
    color: #ffffff !important;
    border-radius: 8px;
}

.selected-jump-button {
    background-color: #b3adbe !important;
    color: #ffffff !important;
}

@keyframes slide-in {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}

.overlay.slide-out {
    animation: slide-out 0.5s ease-out forwards;
}

@keyframes slide-out {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
}

.section-title {
    margin-bottom: 30px;
}

.floating-ask-button {
    position: fixed;
    top: 20%; /* position in the middle of page height */
    right: 0;
    transform: translateY(-50%); /* to center the button vertically */
    z-index: 100; /* to make the button float over other elements */
    border-top-left-radius: 26px !important;
    border-bottom-left-radius: 26px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: 50px !important;
    height: 50px !important;
    margin-right: 0px !important;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 80vh;
    background-color: #F4FAFC;
    border-radius: 6px;
}

.chat-message-list {
    flex: 1;
    padding: 20px;
    padding-right: 30px;
    overflow-y: scroll;
}

.chat-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    color: white;
    box-shadow: 1px 1px 4px #999999;
}

.chat-message.user {
    align-self: flex-end;
    background-color: #00FFFF;
    width: 55%;
    float: right;
    position: relative;
    color: #777777;
    font-weight: 400;
}

.chat-message:not(.user) {
    align-self: flex-start;
    background-color: #dfdfdf;
    width: 55%;
    float: left;
    position: relative;
    color: #555555;
    font-weight: 400;
}

.chat-input {
    display: flex;
    padding: 10px;
}

.chat-input input {
    flex: 1;
    margin-right: 10px;
    padding: 5px;
}

.chat-message.chat-loading {
    font-size: 30px;
    padding: 10px 20px;
    font-weight: 900;
    width: 55%;
    align-self: flex-start;
    background-color: #dfdfdf;
    float: left;
    position: relative;
    color: #555555;
}

.chat-tips {
    color: #888888;
}

.nav-right {
    margin-top: 14px;
    float: right;
    font-size: .9rem;
}

.floating-button-left {
  position: fixed;
  top: 50%;
  left: 0;
  width: 80px;
  height: 80px;
  background: rgba(250, 250, 250, 0.1);
  color: lightgrey;
  border: 1px solid lightgrey;
  border-radius: 50%;
  font-size: 24px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-left: 20px;
}

.floating-button-left:hover {
  background: lightgrey;
  color: white;
}

.floating-button-right {
  position: fixed;
  top: 50%;
  right: 0;
  width: 80px;
  height: 80px;
  background: rgba(250, 250, 250, 0.1);
  color: lightgrey;
  border: 1px solid lightgrey;
  border-radius: 50%;
  font-size: 24px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-right: 20px;
}

.floating-button-right:hover {
  background: lightgrey;
  color: white;
}


.main {

}

.footer {
  float: left;
  background-color: #666666;
  color: #fefefe;
  width: 100%;
  left: 0;
}


.footer .nav {
    float: left;
    margin: 0 1rem;
}

.footer .copyright {
    float: right;
}

.footer  a {
  text-decoration: none;
  color: #fefefe;
}

.footer a:hover {
    border-bottom: 1px solid #971717;
}

footer {
    margin-bottom: 1rem;
    padding: 0.9rem 2rem 0.9rem 2rem;
    font-size: 13px;
}

footer hr {

}

@media screen and (max-width: 480px) { /* Mobile styles */
    .overlay {
        width: 100%;
        padding: 10px 0px;
    }
    .chat-message.user {
        width: 90%;
    }
    .chat-message:not(.user) {
        width: 90%;
    }
    #content {
    }
    .col-md-2, .col-md-4 {
        padding-bottom: 0px !important;
    }
    .floating-button-left {
      width: 40px;
      height: 40px;
      line-height: 30px;
      margin-left: 10px;
      font-size: 18px;
    }
    .floating-button-right {
      width: 40px;
      height: 40px;
      line-height: 30px;
      margin-right: 10px;
      font-size: 18px;
    }
    .floating-button-icons {
        position: relative;
        left: -4px;
    }
}

@media screen and (max-width: 768px) { /* Tablet styles */
    .overlay {
        width: 100%;
    }
    .chat-message.user {
        width: 90%;
    }
    .col-md-2 {
        padding-bottom: 0px !important;
    }
    .floating-button-left {
      width: 50px;
      height: 50px;
      line-height: 30px;
      margin-left: 10px;
      font-size: 18px;
    }
    .floating-button-right {
      width: 50px;
      height: 50px;
      line-height: 30px;
      margin-right: 10px;
      font-size: 18px;
    }
}

@media screen and (min-width: 1024px) { /* Desktop styles */
    .overlay {
        width: 65%;
    }
}

@media (max-width: 767px) {
  .footer .content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .footer {
    position: unset;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer .content,
  .footer {
    font-size: 12px;
  }
}

@media (orientation: landscape) and (max-height: 500px) {
  .footer {
    position: unset;
  }
}
